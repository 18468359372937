<template>
  <v-container class="new-account mt-5 px-10" fluid>
    <PageHeader header-text="New Account" class="mb-7">
      <template #subheader>
        <div>Fill out the form below to create a new account</div>
      </template>
    </PageHeader>

    <div class="d-flex align-center mb-7">
      <div v-if="!accountFields.logoFile" class="upload-background d-flex align-center justify-center" @click="$refs.logo_container.click()">
        <input ref="logo_container" type="file" accept=".jpg, .jpeg, .png" style="width: 0; height: 0" @change="handleFileUpload">
        <v-icon size="18" color="66788e">
          mdi-upload-outline
        </v-icon>
      </div>
      <div v-else class="upload-background d-flex align-center justify-center">
        <img ref="logo_preview" alt="logo preview" style="max-width: 92px; max-height: 92px;">
        <v-icon size="24" color="warning" style="position: absolute" @click="accountFields.logoFile = null">
          mdi-close
        </v-icon>
      </div>
      <div>
        <div class="title-text mb-1">
          Logo
        </div>
        <div class="text-field-hint">
          Choose your account’s logo that will be visible to other users
        </div>
      </div>
    </div>

    <validation-observer v-slot="{ invalid }">
      <v-row class="py-0">
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            v-model="accountFields.name"
            header="Account Name"
            placeholder="My Account"
            :required="true"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomDropdown
            :value="accountFields.accountType"
            header="Account Type"
            placeholder="HCP / Patient"
            :items="accountTypes"
            @change="changeAccountTypeAndDefaultValues($event)"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomDatePicker
            :date="accountFields.subscriptionStartDate"
            placeholder="Pick the date"
            header="Subscription Start Date"
            :required="true"
            @input="(date) => (accountFields.subscriptionStartDate = date)"
          />
        </v-col>
      </v-row>
      <v-row class="py-0">
        <v-col cols="12" sm="4" class="py-0">
          <CustomDropdown
            v-model="accountFields.subscriptionTerm"
            header="Subscription Term"
            placeholder="Select an option"
            :items="subscriptionTerms"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0 pt-2">
          <CustomDropdown
            v-model="accountFields.passwordStrengthLevel"
            header="Required Password Strength"
            placeholder="Select an option"
            :items="passwordStrengthLevels"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0 pt-2">
          <CustomDropdown
            v-model="accountFields.pulseDatabaseId"
            header="Select Pulse Database"
            placeholder="Select an option"
            item-text="displayName"
            item-value="id"
            :items="databases"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomCombobox
            v-model="accountFields.agency"
            subheader="Agency"
            :items="accountAgencies"
            item-value="agency"
            item-text="agency"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            v-model="accountFields.website"
            header="Website"
            placeholder="Enter the main website for this account"
          />
        </v-col>
      </v-row>
      <v-divider class="mt-4 mb-8" />
      <!-- custom fields  -->
      <div
        class="d-flex flex-wrap flex-sm-nowrap align-center mb-3 mb-sm-7 justify-center justify-sm-space-between justify-lg-start"
      >
        <div class="field__subheader">
          Add any custom fields you’d like to use within this account
        </div>
        <div
          class="simple-text-button button--blue mx-10 mx-sm-0 ml-lg-9 mt-4 mt-sm-0"
          @click="addCustomField"
        >
          <v-icon color="#2b84eb" size="16" class="mr-0">
            mdi-plus
          </v-icon>
          Add Field
        </div>
      </div>
      <CustomFields
        :custom_fields="customFields"
        @action="(item, field) => handleAction(item, field)"
      />
      <v-divider class="mt-4 mb-8" />
      <div class="text-center mb-5">
        Account Settings
      </div>
      <div class="field__header">
        KPI Email
      </div>
      <v-row class="py-0">
        <v-col cols="12" sm="6" class="py-0">
          <CustomTextInput
            v-model="accountFields.kpiSettings.email.min_open_rate"
            header="Min Open Rate"
            placeholder="Min Open Rate"
            decimal
          />
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <CustomTextInput
            v-model="accountFields.kpiSettings.email.max_open_rate"
            header="Max Open Rate"
            placeholder="Max Open Rate"
            decimal
          />
        </v-col>
      </v-row>
      <v-row class="py-0">
        <v-col cols="12" sm="6" class="py-0">
          <CustomTextInput
            v-model="accountFields.kpiSettings.email.min_click_rate"
            header="Min Click Rate"
            placeholder="Min Click Rate"
            decimal
          />
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <CustomTextInput
            v-model="accountFields.kpiSettings.email.max_click_rate"
            header="Max Click Rate"
            placeholder="Max Click Rate"
            decimal
          />
        </v-col>
      </v-row>
      <v-row class="py-0">
        <v-col cols="12" sm="6" class="py-0">
          <CustomTextInput
            v-model="accountFields.kpiSettings.email.min_ctor"
            header="Min CTOR"
            placeholder="Min CTOR"
            decimal
          />
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <CustomTextInput
            v-model="accountFields.kpiSettings.email.max_ctor"
            header="Max CTOR"
            placeholder="Max CTOR"
            decimal
          />
        </v-col>
      </v-row>
      <v-divider class="mt-4 mb-8" />
      <div class="field__header">
        KPI Banner
      </div>
      <v-row class="py-0">
        <v-col cols="12" sm="6" class="py-0">
          <CustomTextInput
            v-model="accountFields.kpiSettings.banners.min_click_rate"
            header="Min Click Rate"
            placeholder="Min Click Rate"
            decimal
          />
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <CustomTextInput
            v-model="accountFields.kpiSettings.banners.max_click_rate"
            header="Max Click Rate"
            placeholder="Max Click Rate"
            decimal
          />
        </v-col>
      </v-row>
      <v-divider class="mt-4 mb-8" />
      <v-row class="mt-10">
        <v-col cols="12" sm="6">
          <div class="field__header mb-5">
            Lead Score Look Back Period
          </div>
          <div class="py-0">
            <v-col cols="12" class="py-0 px-0">
              <CustomTextInput
                v-model="accountFields.leadScoreLookBackPeriod"
                type="number"
                hint="This represents the number of months that the lead score calculation will look back when calculating scores."
              />
            </v-col>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-10">
        <v-col cols="12">
          <div class="field__header mb-5">
            Data Retention Period
          </div>
          <div class="py-0">
            <v-col cols="12" class="py-0 px-0">
              <CustomTextInput
                v-model="accountFields.dataRetention"
                type="number"
                hint="This represents the number of months that we will keep certain data such as actions.
                When an action is older than this number of months, it will be removed from the system."
              />
            </v-col>
          </div>
        </v-col>
      </v-row>
      <v-divider class="mt-4 mb-8" />
      <v-row class="mt-12 mb-10">
        <v-col cols="12" class="d-flex justify-end flex-wrap">
          <v-btn
            v-if="newAccount"
            class="custom-button mr-2 px-5"
            height="34px"
            outlined
            :block="$vuetify.breakpoint.xsOnly"
            @click="newAccountDialog = true"
          >
            View Account Creation Status
          </v-btn>
          <v-btn
            v-if="!newAccountFailed"
            class="custom-button custom-button--blue px-13"
            height="34px"
            depressed
            :disabled="!!newAccount || invalid"
            width="150px"
            :block="$vuetify.breakpoint.xsOnly"
            @click="saveAccount"
          >
            {{ newAccount ? 'Creating Account' : 'Create Account' }}
          </v-btn>
          <v-btn
            v-if="newAccountFailed"
            class="custom-button custom-button--blue px-13"
            height="34px"
            depressed
            width="150px"
            :block="$vuetify.breakpoint.xsOnly"
            :disabled="invalid"
            @click="newAccountFailed = false; newAccountDialog = false; newAccount = null;"
          >
            Creation Failed - Retry
          </v-btn>
        </v-col>
      </v-row>
    </validation-observer>

    <v-dialog v-model="dialog" scrollable max-width="560px">
      <AddOrEditCustomFieldDialog
        v-if="dialog"
        :field="activeCustomField"
        :existed-name="existedName"
        @dismiss="dialog = false"
        @save="(data) => saveCustomField(data)"
      />
    </v-dialog>

    <v-dialog v-model="newAccountDialog" scrollable max-width="560px">
      <NewAccountStatusDialog
        v-if="newAccountDialog"
        :account="newAccount"
        @update="(status) => newAccount.status = status"
        @dismiss="newAccountDialog = false"
        @failed="(errMsg) => { newAccountFailed = true; newAccount.failedMessage = errMsg; }"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import CustomDatePicker from "@/sharedComponents/CustomDatePicker";
import CustomFields from "@/views/Accounts/components/CustomFields";
import AddOrEditCustomFieldDialog from "@/views/Accounts/components/AddOrEditCustomFieldDialog";
import NewAccountStatusDialog from "@/views/Accounts/components/NewAccountStatusDialog";
import CustomCombobox from "@/sharedComponents/CustomCombobox";
import {parseImageFileToBase64} from "@/utils/helper";
import dayjs from "dayjs";

export default {
  name: "NewAccountView",
  metaInfo: {
    title: 'Create Account'
  },
  components: {
    PageHeader,
    CustomTextInput,
    CustomDropdown,
    CustomDatePicker,
    CustomFields,
    AddOrEditCustomFieldDialog,
    NewAccountStatusDialog,
    CustomCombobox,
  },
  data() {
    return {
      logo_preview: '',
      accountFields: {
        logoFile: null,
        name: null,
        pulseDatabaseId: null,
        accountType: 'HCP',
        subscriptionTerm: 12,
        passwordStrengthLevel: "3",
        subscriptionStartDate: dayjs().utc().format('YYYY-MM-DD'),
        customFieldDefinition: {
          contact: [],
        },
        kpiSettings: {
          email: {
            min_open_rate: 5,
            max_open_rate: 12,
            min_click_rate: 1,
            max_click_rate: 3,
            min_ctor: 20,
            max_ctor: 25,
          },
          banners: {
            min_click_rate: 0.1,
            max_click_rate: 2.0
          }
        },
        leadScoreLookBackPeriod: 12,
        dataRetention: 25,
        agency: null,
        website: null,
      },
      activeCustomField: {},
      dialog: false,
      customFields: [],
      accountTypes: [
        { label: "HCP", value: "HCP" },
        { label: "Patient", value: "Patient" },
      ],
      subscriptionTerms: [
        { label: "1 month", value: 1 },
        { label: "3 months", value: 3 },
        { label: "1 year", value: 12 },
      ],
      passwordStrengthLevels: [
        { label: "Medium", value: "2" },
        { label: "Strong", value: "3" },
        { label: "Very Strong", value: "4" }
      ],
      databases: [],
      newAccountDialog: false,
      newAccountFailed: false,
      newAccount: null,
      accountAgencies: [],
    };
  },
  computed: {
    existedName() {
      return this.customFields.map(field => field.name);
    }
  },
  async created() {
    await this.getDatabases();
    await this.getAgencies();
  },
  methods: {
    changeAccountTypeAndDefaultValues(type) {
      this.accountFields.accountType = type

      this.accountFields.kpiSettings.email = {
        min_open_rate: type === 'Patient' ? 11 : 5,
        max_open_rate:  type === 'Patient' ? 15 : 12,
        min_click_rate: type === 'Patient' ? 3 : 1,
        max_click_rate: type === 'Patient' ? 5 : 3,
        min_ctor: type === 'Patient' ? 27 : 20,
        max_ctor: type === 'Patient' ? 33 : 25,
      }
    },
    async handleFileUpload(event) {
      let file = null;
      if (event.target.files.length) {
        file = event.target.files[0];
      }

      if (!(file instanceof File)) {
        this.accountFields.logoFile = {};
        this.$refs.logo_preview.src = '';
        return;
      }

      parseImageFileToBase64(file).then(imgLoaded => {
        this.accountFields.logoFile = {
          data: imgLoaded.data,
          fileName: imgLoaded.name,
        };
        this.$nextTick(() => {
          this.$refs.logo_preview.src = imgLoaded.data;
        })
      }).catch(() => {
        this.accountFields.logoFile = {};
        this.$refs.logo_preview.src = '';
      });
    },
    async getDatabases() {
      const resp = await this.$rest.admin.getDatabaseCollection({});
      this.databases = resp.data.items;
      this.databases.unshift({id: null, displayName: 'Use database server with the least accounts'});
    },
    async saveAccount() {
      this.accountFields.customFieldDefinition.contact = this.customFields;
      const result = await this.$rest.account.post_resource(this.accountFields);
      this.newAccount = result.data;
      this.newAccountDialog = true;
    },
    addCustomField() {
      this.clearActiveCustomField();
      this.dialog = true;
    },
    clearActiveCustomField() {
      this.activeCustomField = {};
    },
    async handleAction(item, field) {
      this.activeCustomField = field;
      if (item.value === "delete") {
        for (let i = 0; i < this.customFields.length; i++) {
          if (field.name === this.customFields[i].name) {
            this.customFields.splice(i, 1);
            break;
          }
        }
      } else if (item.value === "edit") {
        this.dialog = true;
      }
    },
    async saveCustomField(field) {
      for (let i = 0; i < this.customFields.length; i++) {
        if (field.id === this.customFields[i].id) {
          this.customFields.splice(i, 1);
          break;
        }
      }
      this.customFields.push(field);
    },
    async getAgencies() {
      this.accountAgencies = (await this.$rest.account.getAgencies()).data;
    }
  },
};
</script>

<style lang="scss" scoped>
.new-account {
  .upload-background {
    cursor: pointer;
    width: 95px;
    min-width: 95px;
    height: 95px;
    margin: 0 14px 0 0;
    border-radius: 3px;
    background-color: #ecf1f5;
    * {
      cursor: pointer;
    }
  }

  .v-input.v-input--selection-controls {
    margin: 0;
    .v-input__slot {
      margin-bottom: 0;
    }
  }
}
.field {
  &__header {
    margin-bottom: 25px;
  }
}

::v-deep .custom-button--blue.theme--light.v-btn.v-btn--disabled {
  background-color: #80b1e9 !important;
}
</style>
